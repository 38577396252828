export const data = [
	{
		to: '/',
		text: 'Về Chúng Tôi',
		id: 'about',
	},
	{
		to: '/news',
		text: 'Thông Tin Đấu Giá',
	},
	{
		to: '/contact',
		text: 'Liên Hệ',
	},
];
